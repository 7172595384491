import { type InitOptions } from "i18next";

export const i18nConfig = {
  supportedLngs: [
    "de-DE",
    "en-US",
    "es-ES",
    "fr-FR",
    "it-IT",
    "ja-JP",
    "nl-NL",
    "pt-BR",
  ],
  fallbackLng: "en-US",
  returnObjects: true,
  interpolation: {
    escapeValue: false,
  },
  react: { useSuspense: true },
} satisfies InitOptions;
